export const loadState = (key = 'state') => {
    try {
        const serializedState = localStorage.getItem(key);

        if (serializedState) {
          return JSON.parse(serializedState);
        }
    } catch (err) {
        return undefined;
    }
}

export const saveState = (data, key = 'state') => {
    try {
        const serializedState = JSON.stringify(data);
        localStorage.setItem(key, serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};
